.pagination {
    text-align: right;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-4);
    align-items: center;
}

.carouselContent {
    display: flex;
    gap: var(--spacing-5);
}
.svgRotate {
    transform: rotate(180deg);
}
.svgAlign {
    vertical-align: bottom;
}
