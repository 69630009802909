.article {
    width: 230px;
    position: relative;
    padding-bottom: var(--spacing-2);
    overflow: hidden;

    @media --md {
        width: 340px;
    }
}

.article p:last-of-type {
    margin-bottom: 0;
}

.article:active {
    cursor: grabbing;
}

.actionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: var(--spacing-2);
    gap: var(--spacing-4);
}

.content {
    display: flex;
    text-decoration: none;
    color: inherit;
    align-items: baseline;
    flex-direction: column;
    margin-top: var(--spacing-2);
    gap: var(--spacing-4);
}

.actionSpan {
    display: flex;
    align-items: center;
    font-size: var(--font-size-xs);
    height: var(--spacing-6);
    gap: 10px;

    &:hover {
        color: var(--color-neutral-700);
    }
}